
module.exports = {
  google: {
    API_KEY: "AIzaSyC46kD03HMaBUpLAGhw5AJ8O7NYebT4TFA",
    CLIENT_ID: "190146698321-3l3nr1am4hbq8mv4a45pv9nal4d31b2v.apps.googleusercontent.com",
    SECRET: "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",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    API_URL: "https://ds.uanodes.com.ua",
  }
};


