import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { logoutUser } from "../../slices/auth/login/utils";

//redux
import { useDispatch } from "react-redux";

import withRouter from "../../Components/Common/withRouter";

const Logout = (props) => {
  const dispatch = useDispatch();

  // const isUserLogoutSelector = createSelector(
  //   (state) => state.Login.isUserLogout,
  //   (isUserLogout) => isUserLogout
  // );
  // const isUserLogout = useSelector(isUserLogoutSelector);

  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  // if (isUserLogout) {
    return <Navigate to="/login" />;
  // }

  // return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);