import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Offcanvas,
  OffcanvasBody,
  Row,
  UncontrolledDropdown,
  FormFeedback,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import {toast, ToastContainer} from "react-toastify";


//redux
// import { useSelector, useDispatch } from "react-redux";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
// import { createSelector } from "reselect";
import {createFarmApi, getFarmsApi, updateFarmApi, deleteFarmApi} from "../../../helpers/backend_helper";

const Team = () => {
  document.title = "Farms | UANodes";

  // const dispatch = useDispatch();

  // const teamData = useSelector(selectDashboardData);
  const [team, setTeam] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);
  const [teamListCopy, setTeamlistCopy] = useState([]);
  const [teamList, setTeamlist] = useState([]);

  useEffect(() => {
    // Fetch farms when the component mounts
    getFarmsApi()
        .then((data) => {
          setTeamlist(data.results);
          setTeamlistCopy(data.results);
        })
        .catch((error) => {
          // console.error(error);
          toast.error('Error fetching farms', { position: "top-right" })
        });
  }, []);

  //Modal
  const [teamMem, setTeamMem] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [errors, setErrors] = useState({});

  // useEffect(() => {
  //   dispatch(onGetTeamData());
  // }, [dispatch]);

  // useEffect(() => {
  //   // setTeam(teamData);
  //   // setTeamlist(teamData);
  //   setTeamlistCopy(teamList);
  // }, [teamList]);

  // Create a function to update an element in teamList
  const updateItemInTeamList = (updatedItem) => {
    setTeamlist((prevTeamList) => {
      return prevTeamList.map((item) => {
        if (item.id === updatedItem.id) {
          // Update the item with the updatedItem data
          return updatedItem;
        }
        return item;
      });
    });
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setTeamMem(null);
    } else {
      setErrors({});
      validation.resetForm();
      setModal(true);
    }
  }, [modal]);

  // Update To do
  const handleTeamClick = useCallback(
    (arg) => {
      const teamMem = arg;
      setTeamMem({
        id: teamMem.id,
        name: teamMem.name,
        count_accounts: teamMem.count_accounts,
        count_tasks: teamMem.count_tasks,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );
  //
  // const handleTeamOpen = useCallback(
  //     (arg) => {
  //       const teamMem = arg;
  //       setTeamMem({
  //         id: teamMem.id,
  //         name: teamMem.name,
  //         count_accounts: teamMem.count_accounts,
  //         count_tasks: teamMem.count_tasks,
  //       });
  //     },
  //     [toggle]
  // );

  // Add To do
  const handleTeamClicks = () => {
    setTeamMem("");
    setModal(!modal);
    setIsEdit(false);
    toggle();
  };

  // delete
  const onClickData = (team) => {
    setTeam(team);
    setDeleteModal(true);
  };

  const handleDeleteTeamData = () => {
    if (team) {
      deleteFarmApi(team)
          .then(() => {
            setTeamlist((prevTeamList) => prevTeamList.filter((item) => item.id !== team.id));
          })
          .catch((error) => {
            // console.error(error);
            toast.error('Error deleting farm', { position: "top-right" })
          });
      // dispatch(onDeleteTeamData(team.id));
      setDeleteModal(false);
      setTeam(null);
    }
  };

  useEffect(() => {
    const list = document.querySelectorAll(".team-list");
    const buttonGroups = document.querySelectorAll(".filter-button");
    for (let i = 0; i < buttonGroups.length; i++) {
      buttonGroups[i].addEventListener("click", onButtonGroupClick);
    }

    function onButtonGroupClick(event) {
      if (
        event.target.id === "list-view-button" ||
        event.target.parentElement.id === "list-view-button"
      ) {
        document.getElementById("list-view-button").classList.add("active");
        document.getElementById("grid-view-button").classList.remove("active");
        list.forEach(function (el) {
          el.classList.add("list-view-filter");
          el.classList.remove("grid-view-filter");
        });
      } else {
        document.getElementById("grid-view-button").classList.add("active");
        document.getElementById("list-view-button").classList.remove("active");
        list.forEach(function (el) {
          el.classList.remove("list-view-filter");
          el.classList.add("grid-view-filter");
        });
      }
    }
  }, []);

  const favouriteBtn = (ele) => {
    if (ele.closest("button").classList.contains("active")) {
      ele.closest("button").classList.remove("active");
    } else {
      ele.closest("button").classList.add("active");
    }
  };

  const searchList = (e) => {
    let inputVal = e.toLowerCase();

    const filterItems = (arr, query) => {
      return arr.filter((el) => {
        return el.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
    };

    // let filterData = filterItems(teamData, inputVal);
    let filterData = filterItems(teamListCopy, inputVal);

    setTeamlist(filterData);

    if (filterData.length === 0) {
      document.getElementById("noresult").style.display = "block";
      document.getElementById("teamlist").style.display = "none";
    } else {
      document.getElementById("noresult").style.display = "none";
      document.getElementById("teamlist").style.display = "block";
    }
  };

  //OffCanvas
  const [isOpen, setIsOpen] = useState(false);
  const [sideBar, setSideBar] = useState([]);

  //Dropdown
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggledropDown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (teamMem && teamMem.name) || "",
      // designation: (teamMem && teamMem.designation) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter farm name"),
    }),
    onSubmit: (values) => {
      setErrors({});
      // validation.resetForm();

      if (isEdit) {
        const updateTeamData = {
          ...teamMem,
          name: values.name,
        };
        // save edit Team data
        // dispatch(onUpdateTeamData(updateTeamData));
        updateFarmApi(updateTeamData)
            .then((updatedTeamData) => {
              // Call the function to update the element in teamList
              updateItemInTeamList(updatedTeamData);
              setModal(false);
            })
            .catch((error) => {
              if (error?.response?.data?.errors && error?.response?.status === 400) {
                setErrors(error.response.data.errors);
              }else{
                // setErrors('Unexpected error occurred.')
                toast.error('Error updating farm', { position: "top-right" })
              }
            });
        // validation.resetForm();
      } else {
        const newTeamData = {
          ...teamMem,
          name: values.name,
        };
        // save new TeamData
        // dispatch(onAddTeamData(newTeamData));
        createFarmApi(newTeamData)
            .then((createdTeamData) => {
              // Call the function to update the element in teamList
              let updatedTeamList = [createdTeamData, ...teamList]
              setTeamlist(updatedTeamList);
              setTeamlistCopy(updatedTeamList);
              setModal(false);
            })
            .catch((error) => {
              // console.error(error);
              if (error?.response?.data?.errors && error?.response?.status === 400) {
                setErrors(error.response.data.errors);
              }else{
                // setErrors('Unexpected error occurred.')
                toast.error('Error creating farm', { position: "top-right" })
              }
            });
        // validation.resetForm();
      }
      if (!errors){
        toggle();
        validation.resetForm();
      }
    },
  });
  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTeamData()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Farms" />
          <Card>
            <CardBody>
              <Row className="g-2">
                <Col sm={4}>
                  <div className="search-box">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Search for name or designation..."
                      onChange={(e) => searchList(e.target.value)}
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </Col>
                <Col className="col-sm-auto ms-auto">
                  <div className="list-grid-nav hstack gap-1">
                    <Button
                      color="info"
                      id="grid-view-button"
                      className="btn btn-soft-info nav-link btn-icon fs-14 active filter-button"
                    >
                      <i className="ri-grid-fill"></i>
                    </Button>
                    <Button
                      color="info"
                      id="list-view-button"
                      className="btn btn-soft-info nav-link  btn-icon fs-14 filter-button"
                    >
                      <i className="ri-list-unordered"></i>
                    </Button>
                    {/*<Dropdown isOpen={dropdownOpen} toggle={toggledropDown}>*/}
                    {/*  <DropdownToggle*/}
                    {/*    type="button"*/}
                    {/*    className="btn btn-soft-info btn-icon fs-14"*/}
                    {/*  >*/}
                    {/*    <i className="ri-more-2-fill"></i>*/}
                    {/*  </DropdownToggle>*/}
                    {/*  <DropdownMenu>*/}
                    {/*    <li>*/}
                    {/*      <Link className="dropdown-item" to="#">*/}
                    {/*        All*/}
                    {/*      </Link>*/}
                    {/*    </li>*/}
                    {/*    <li>*/}
                    {/*      <Link className="dropdown-item" to="#">*/}
                    {/*        Last Week*/}
                    {/*      </Link>*/}
                    {/*    </li>*/}
                    {/*    <li>*/}
                    {/*      <Link className="dropdown-item" to="#">*/}
                    {/*        Last Month*/}
                    {/*      </Link>*/}
                    {/*    </li>*/}
                    {/*    <li>*/}
                    {/*      <Link className="dropdown-item" to="#">*/}
                    {/*        Last Year*/}
                    {/*      </Link>*/}
                    {/*    </li>*/}
                    {/*  </DropdownMenu>*/}
                    {/*</Dropdown>*/}
                    <Button color="success"
                            onClick={() => handleTeamClicks()}
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Add new
                      Farm
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Row>
            <Col lg={12}>
              <div id="teamlist">
                <Row className="team-list grid-view-filter">
                  {(teamList || []).map((item, key) => (
                    <Col key={key}>
                      <Card className="team-box">
                        <div className="team-cover">
                          <img
                            src={item.backgroundImg}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <CardBody className="p-4">
                          <Row className="align-items-center team-row">
                            <Col className="team-settings">
                              <Row>
                                {/* <Col>
                                                                    <div className="flex-shrink-0 me-2">
                                                                        <button type="button" className="btn btn-light btn-icon rounded-circle btn-sm favourite-btn" onClick={(e) => favouriteBtn(e.target)}>
                                                                            <i className="ri-star-fill fs-14"></i>
                                                                        </button>
                                                                    </div>
                                                                </Col> */}
                                <UncontrolledDropdown
                                  direction="start"
                                  className="col text-end"
                                >
                                  <DropdownToggle
                                    tag="a"
                                    id="dropdownMenuLink2"
                                    role="button"
                                  >
                                    <i className="ri-more-fill fs-17"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      className="dropdown-item edit-list"
                                      // href="#addmemberModal"
                                      onClick={() => handleTeamClick(item)}
                                    >
                                      <i className="ri-pencil-line me-2 align-bottom text-muted"></i>
                                      Edit
                                    </DropdownItem>
                                    <DropdownItem
                                      className="dropdown-item remove-list"
                                      // href="#removeMemberModal"
                                      onClick={() => onClickData(item)}
                                    >
                                      <i className="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>
                                      Remove
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </Row>
                            </Col>
                            <Col lg={4} className="col">
                              <div className="team-profile-img">
                                {/* <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                                                                    {item.userImage != null ?
                                                                        <img src={item.userImage} alt="" className="img-fluid d-block rounded-circle" />

                                                                        :
                                                                        <div className="avatar-title text-uppercase border rounded-circle bg-light text-primary">
                                                                            {item.name.charAt(0) + item.name.split(" ").slice(-1).toString().charAt(0)}
                                                                        </div>}
                                                                </div> */}
                                <div className="team-content">
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      setIsOpen(!isOpen);
                                      setSideBar(item);
                                    }}
                                  >
                                    <h5 className="fs-16 mb-1">{item.name}</h5>
                                  </Link>
                                  {/* <p className="text-muted mb-0">{item.designation}</p> */}
                                </div>
                              </div>
                            </Col>
                            <Col lg={4} className="col">
                              <Row className="text-muted text-center">
                                <Col
                                  xs={6}
                                  className="border-end border-end-dashed"
                                >
                                  <h5 className="mb-1">{item.count_accounts}</h5>
                                  <p className="text-muted mb-0">Accounts</p>
                                </Col>
                                <Col xs={6}>
                                  <h5 className="mb-1">{item.count_tasks}</h5>
                                  <p className="text-muted mb-0">Tasks</p>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={2} className="col">
                              <div className="text-end">
                                <Link
                                  to={`/farms/${item.id}/accounts`}
                                  className="btn btn-light view-btn"
                                >
                                  Open farm
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}

                  <Col lg={12}>
                    <div className="text-center mb-3">
                      {/*<Link to="#" className="text-success">*/}
                      {/*  <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>{" "}*/}
                      {/*  Load More{" "}*/}
                      {/*</Link>*/}
                    </div>
                  </Col>
                </Row>

                <div
                  className="modal fade"
                  id="addmembers"
                  tabIndex="-1"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <Modal isOpen={modal} toggle={toggle} centered>
                      <ModalBody>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <Row>
                            <Col lg={12}>
                              <input
                                type="hidden"
                                id="memberid-input"
                                className="form-control"
                                defaultValue=""
                              />
                              <div className="px-1 pt-1">
                                <div className="modal-team-cover position-relative mb-0 mt-n4 mx-n4 rounded-top overflow-hidden">
                                  <img
                                    // src={smallImage9}
                                    alt=""
                                    id="cover-img"
                                    className="img-fluid"
                                  />

                                  <div className="d-flex position-absolute start-0 end-0 top-0 p-3">
                                    <div className="flex-grow-1">
                                      <h5
                                        className="modal-title text-white"
                                        id="createMemberLabel"
                                      >
                                        {!isEdit
                                          ? "Add New Farm"
                                          : "Edit a Farm"}
                                      </h5>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <div className="d-flex gap-3 align-items-center">
                                        {/*<div>*/}
                                        {/*  <label*/}
                                        {/*    htmlFor="cover-image-input"*/}
                                        {/*    className="mb-0"*/}
                                        {/*    data-bs-toggle="tooltip"*/}
                                        {/*    data-bs-placement="top"*/}
                                        {/*    title="Select Cover Image"*/}
                                        {/*  >*/}
                                        {/*    <div className="avatar-xs">*/}
                                        {/*      <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">*/}
                                        {/*        <i className="ri-image-fill"></i>*/}
                                        {/*      </div>*/}
                                        {/*    </div>*/}
                                        {/*  </label>*/}
                                        {/*  <input*/}
                                        {/*    className="form-control d-none"*/}
                                        {/*    defaultValue=""*/}
                                        {/*    id="cover-image-input"*/}
                                        {/*    type="file"*/}
                                        {/*    accept="image/png, image/gif, image/jpeg"*/}
                                        {/*  />*/}
                                        {/*</div>*/}
                                        {/*<button*/}
                                        {/*  type="button"*/}
                                        {/*  className="btn-close btn-close-white"*/}
                                        {/*  id="createMemberBtn-close"*/}
                                        {/*  data-bs-dismiss="modal"*/}
                                        {/*  aria-label="Close"*/}
                                        {/*></button>*/}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="text-center mb-4 mt-n5 pt-2">
                                <div className="position-relative d-inline-block">
                                  <div className="position-absolute bottom-0 end-0">
                                    {/* <label htmlFor="member-image-input" className="mb-0" data-bs-toggle="tooltip" data-bs-placement="right" title="Select Member Image">
                                                                            <div className="avatar-xs">
                                                                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                                                                    <i className="ri-image-fill"></i>
                                                                                </div>
                                                                            </div>
                                                                        </label> */}
                                    <input
                                      className="form-control d-none"
                                      defaultValue=""
                                      id="member-image-input"
                                      type="file"
                                      accept="image/png, image/gif, image/jpeg"
                                    />
                                  </div>
                                  {/* <div className="avatar-lg">
                                                                        <div className="avatar-title bg-light rounded-circle">
                                                                            <img src={userdummyimg} alt=" " id="member-img" className="avatar-md rounded-circle h-auto" />
                                                                        </div>
                                                                    </div> */}
                                </div>
                              </div>

                              <div className="mb-3">
                                <Label
                                  htmlFor="teammembersName"
                                  className="form-label"
                                >
                                  Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="teammembersName"
                                  placeholder="Enter name"
                                  name="name"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.name || ""}
                                  invalid={
                                    validation.touched.name &&
                                    validation.errors.name
                                      ? true
                                      : !!errors?.name
                                  }
                                />
                                {validation.touched.name &&
                                validation.errors.name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.name}
                                  </FormFeedback>
                                ) : errors && errors?.name ? (
                                    <FormFeedback type="invalid"><div>{errors.name}</div></FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            {/* <Col lg={12}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="designation" className="form-label">Designation</Label>
                                                                <Input type="text" className="form-control" id="designation" placeholder="Enter designation" name='designation'
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.designation || ""}
                                                                    invalid={
                                                                        validation.touched.designation && validation.errors.designation ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.designation && validation.errors.designation ? (
                                                                    <FormFeedback type="invalid">{validation.errors.designation}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col> */}
                            <Col lg={12}>
                              <div className="hstack gap-2 justify-content-end">
                                <button
                                  type="button"
                                  className="btn btn-light"
                                  onClick={() => {
                                    setModal(false);
                                    // validation.resetForm();
                                  }}
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                  id="addNewMember"
                                >
                                  {!isEdit ? "Add new Farm" : "Save"}
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>
                  </div>
                </div>
              </div>
              <div
                className="py-4 mt-4 text-center"
                id="noresult"
                style={{ display: "none" }}
              >
                <lord-icon
                  src="https://cdn.lordicon.com/msoeawqm.json"
                  trigger="loop"
                  colors="primary:#405189,secondary:#0ab39c"
                  style={{ width: "72px", height: "72px" }}
                ></lord-icon>
                <h5 className="mt-4">Sorry! No Result Found</h5>
              </div>
            </Col>
          </Row>

          <svg className="bookmark-hide">
            <symbol
              viewBox="0 0 24 24"
              stroke="currentColor"
              fill="var(--color-svg)"
              id="icon-star"
            >
              <path
                strokeWidth=".4"
                d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
              ></path>
            </symbol>
          </svg>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Team;
