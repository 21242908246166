import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from './isAuthenticated';
import {setAuthorization} from "../../helpers/api_helper";

const withAuthentication = (WrappedComponent) => {
    const EnhancedComponent = (props) => {
        const navigate = useNavigate();

        useEffect(() => {
            const checkAuth = async () => {
                const authenticated = await isAuthenticated();
                if (!authenticated) {
                    navigate('/login');
                }
                let access_token = localStorage.getItem('access_token');
                setAuthorization(access_token);
            };
            checkAuth();
        }, [navigate]);

        return <WrappedComponent {...props} />;
    };

    return EnhancedComponent;
};

export default withAuthentication;
