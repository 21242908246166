import React, { useEffect, useState, useCallback, useMemo } from "react";
import {Link, useParams} from "react-router-dom";
import * as moment from "moment";
import Flatpickr from "react-flatpickr";

import {
  Button,
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback,
} from "reactstrap";

import DeleteModal from "../../Components/Common/DeleteModal";
import AddToFarmModal from "../../Components/Common/AddToFarmModal";

import WidgetsTask from "./WidgetsTask";

import TableContainer from "../../Components/Common/TableContainer";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Export Modal
import ExportCSVModal from "../../Components/Common/ExportCSVModal";

import {
  createAccountApi, createFarmAccountApi,
  deleteAccountApi,
  getAccountsApi, getFarm,
  getFarmAccountsApi,
  getFarmsApi, importTokensApi, importTokensFarmApi, manageAccountsApi,
  updateAccountApi,
} from "../../helpers/backend_helper";
import DeleteFromFarmModal from "../../Components/Common/DeleteFromFarm";
import ImportTokensModal from "../../Components/Common/ImportTokensModal";


const CrmContacts = () => {
  const {farmId} = useParams();

  const [isEdit, setIsEdit] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [contact, setContact] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [farm, setFarm] = useState({});
  const [farms, setFarms] = useState([]);

  useEffect(() => {
    // Fetch farms when the component mounts
    getFarmsApi()
        .then((data) => {
          setFarms(data.results);
        })
        .catch((error) => {
          toast.error('Error fetching farms', { position: "top-right" })
        });
  }, []);

  const fetchAccounts = () => {
    let apiMethod;
    if (farmId) {
      apiMethod = getFarmAccountsApi;
    }else{
      apiMethod = getAccountsApi;
    }
    apiMethod({farmId})
        .then((data) => {
          setAccounts(data.results);
        })
        .catch((error) => {
          // console.error(error);
          toast.error('Error fetching accounts', { position: "top-right" })
        });
  };

  useEffect(() => {
    fetchAccounts();

    if (farmId){
      getFarm({id: farmId}).then(data => setFarm(data));
    }else{
      setFarm({})
    }
  }, [farmId]);

  //delete Contact
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [addToFarmModalMulti, setAddToFarmModalMulti] = useState(false);
  const [deleteFromFarmModalMulti, setDeleteFromFarmModalMulti] = useState(false);
  const [importTokensModal, setImportTokensModal] = useState(false);
  const [createMulti, setCreateMulti] = useState(false);
  const [isCreateButton, setIsCreateButton] = useState(false);

  const create = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      // dispatch(deleteTask(element.value));  // delete with checkboxes
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsCreateButton(false);
    checkall.checked = false;
  };

  const createbox = () => {
    const ele = document.querySelectorAll(".taskCheckBox:checked");
    ele.length > 0 ? setIsCreateButton(true) : setIsCreateButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const [modal, setModal] = useState(false);
  const [modalTask, setModalTask] = useState(false);
  const [errors, setErrors] = useState({});


  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setErrors({});
      validation.resetForm();
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const toggleTask = useCallback(() => {
    if (modalTask) {
      setModalTask(false);
      setContact(null);
    } else {
      setModalTask(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modalTask]);

  // Delete Data
  const handleDeleteContact = () => {
    if (contact) {
      deleteAccountApi(contact)
          .then(() => {
            setAccounts((prevAccount) => prevAccount.filter((item) => item.id !== contact.id));
          })
          .catch((error) => {
            // console.error(error);
            toast.error('Error deleting account', { position: "top-right" })
          });
      // dispatch(onDeleteContact(contact._id));  // TODO: DELETE Contact = Account
      setDeleteModal(false);
      setContact("");
    }
  };

  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };

  // Add Data
  const handleContactClicks = () => {  // Create Contact
    validation.resetForm();
    setErrors({});
    setContact("");
    setIsEdit(false);
    setIsEdited(false);
    toggle();
  };

  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return d.getDate() + " " + months[d.getMonth()] + ", " + d.getFullYear();
  };
  const dateformate = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
    setDate(joinDate);
  };

  //createTask
  const [task, setTask] = useState([]);
  const valid = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      taskId: (task && task.taskId) || "",
      project: (task && task.project) || "",
      task: (task && task.task) || "",
      creater: (task && task.creater) || "",
      dueDate: (task && task.dueDate) || "",
      status: (task && task.status) || "New",
      priority: (task && task.priority) || "High",
      subItem: (task && task.subItem) || [],
    },
    taskSchema: Yup.object({
      taskId: Yup.string().required("Please Enter Task Id"),
      project: Yup.string().required("Please Enter Project Name"),
      task: Yup.string().required("Please Enter Your Task"),
      creater: Yup.string().required("Please Enter Creater Name"),
      // dueDate: Yup.string().required("Please Enter Due Date"),
      status: Yup.string().required("Please Enter Status"),
      priority: Yup.string().required("Please Enter Priority"),
      subItem: Yup.array().required("Please Enter"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatedTask = {
          _id: task ? task._id : 0,
          taskId: values.taskId,
          project: values.project,
          task: values.task,
          creater: values.creater,
          dueDate: date,
          status: values.status,
          priority: values.priority,
          subItem: values.subItem,
        };
        // update customer
        // dispatch(updateTask(updatedTask));  // UPDATE TASK
        valid.resetForm();
      } else {
        const newTask = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          taskId: values["taskId"],
          project: values["project"],
          task: values["task"],
          creater: values["creater"],
          dueDate: date,
          status: values["status"],
          priority: values["priority"],
          subItem: values["subItem"],
        };
        // save new customer
        // dispatch(addNewTask(newTask));  // ADD NEW TASK
        task.resetForm();
      }
      toggleTask();
    },
  });
  const defaultdate = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };
  const [date, setDate] = useState(defaultdate());

  // Create a function to update an element in accounts
  const updateItemInAccounts = (updatedItem) => {
    setAccounts((prevAccounts) => {
      return prevAccounts.map((item) => {
        if (item.id === updatedItem.id) {
          // Update the item with the updatedItem data
          return updatedItem;
        }
        return item;
      });
    });
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      username: (contact && contact.username) || "",
      token: (contact && contact.token) || "",
      email: (contact && contact.email) || "",
      phone: (contact && contact.phone) || "",
      password: (contact && contact.password) || "",
      // tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      // username: Yup.string().required("Please Enter Username"),
      token: Yup.string().required("Please Enter Token"),
      // email: Yup.string().required("Please Enter Email"),
      // phone: Yup.string().required("Please Enter Phone"),
      // password: Yup.string().required("Please Enter Password"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          id: contact ? contact._id : 0,
          // img: values.img,
          username: values.username,
          token: values.token,
          email: values.email,
          phone: values.phone,
          password: values.password,
          // joined: dateFormat(),
          // time: timeFormat(),
          // tags: assignTag,
        };
        // update Contact
        // dispatch(onUpdateContact(updateContact));  // UpdateContact = account
        updateAccountApi(updateContact)
            .then((updatedContact) => {
              // Call the function to update the element in teamList
              updateItemInAccounts(updatedContact);
              setModal(false);
            })
            .catch((error) => {
              if (error?.response?.data?.errors && error?.response?.status === 400) {
                setErrors(error.response.data.errors);
              }else{
                toast.error('Error updating account', { position: "top-right" })
              }
            });
        // setIsEdit(false);
        // validation.resetForm();
      } else {
        setContact(null);
        setIsEdit(false);

        const newAccount = {
          farmId,

          // img: values["img"],
          username: values["username"],
          token: values["token"],
          email: values["email"],
          phone: values["phone"],
          password: values["password"],
          // joined: dateFormat(),
          // time: timeFormat(),
          // tags: assignTag,
        };
        // save new Contact
        // dispatch(onAddNewContact(newContact));  // TODO:  UpdateContact = account
        let apiMethod;

        if (farmId) {
          apiMethod = createFarmAccountApi;
        }else{
          apiMethod = createAccountApi;
        }
        apiMethod(newAccount)
            .then((createdAccount) => {
              // Call the function to update the element in teamList
              let updated = [createdAccount, ...accounts]
              setAccounts(updated);
              setModal(false);
            })
            .catch((error) => {
              // console.error(error);
              if (error?.response?.data?.errors && error?.response?.status === 400) {
                setErrors(error.response.data.errors);
              }else{
                toast.error('Error creating account', { position: "top-right" })
              }
            });

        // validation.resetForm();
      }

      if (!errors) {
        if (isEdit){
          setIsEdit(false);
        }
        validation.resetForm();
        toggle();
      }

    },
  });

  // Update Data
  const handleContactClick = useCallback(
    (arg) => {
      const contact = arg;

      setContact({
        _id: contact.id,
        // img: contact.img,
        username: contact.username,
        token: contact.token,
        email: contact.email,
        phone: contact.phone,
        password: contact.password,
        joined: contact.joined,
        // time: contact.time,
        // tags: contact.tags,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime =
      moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
    return updateTime;
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      // console.warn(element.value);
      // dispatch(onDeleteContact(element.value));  // delete contact = account
      deleteAccountApi({id:element.value})
          .then(() => {
            setAccounts((prevAccount) => prevAccount.filter((item) => item.id != element.value));
          })
          .catch((error) => {
            // console.error(error);
            toast.error('Error deleting account', { position: "top-right" })
          });
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const manageAccountsMultiple = (id, operation) => {
    const checkall = document.getElementById("checkBoxAll");
    const accountIds = Array.from(selectedCheckBoxDelete).map((checkbox) => Number(checkbox.value));

    const requestData = {
      accounts: accountIds,
      operation: operation,
      farm_id: id,
    };

    manageAccountsApi(requestData)
        .then((data) => {
          if (data?.success) {
            fetchAccounts();
            toast.success(data.message, { position: "top-right" });
          }else{
            toast.error(data?.message ? data.message : "Error adding to Farm", { position: "top-right" });
          }
          // setAccounts((prevAccount) => prevAccount.filter((item) => item.id != element.value));
        })
        .catch((error) => {
          let err = error?.response?.data?.message;
          toast.error(err ? err : 'Error adding to Farm', { position: "top-right" });
        });

    setIsMultiDeleteButton(false);
    checkall.checked = false;
    checkedAll();
  };

  const importTokens = (tokens) => {

    const requestData = {
      'tokens': tokens,
      farmId
    };

    let apiMethod;
    if (farmId) {
      apiMethod = importTokensFarmApi;
    }else{
      apiMethod = importTokensApi;
    }

    apiMethod(requestData)
        .then((data) => {
          if (data?.success) {
            fetchAccounts();
            toast.success(data.message, {position: "top-right"});
          }else{
            toast.error(data?.message ? data.message : "Error importing Tokens", { position: "top-right" });
          }
        })
        .catch((error) => {
          let err = error?.response?.data?.message;
          let err_tokens = error?.response?.data?.errors?.tokens;

          if (err){
            toast.error(err ? err : 'Error adding to Farm', { position: "top-right" });
          }else if (err_tokens){
            toast.error('Error importing Tokens. Some tokens was invalid', { position: "top-right" });
          }else{
            toast.error('Error importing Tokens', { position: "top-right" })
          }

        });

  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  // Customer Column
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="contactCheckBox form-check-input"
              value={cellProps.row.original.id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      // {
      //   Header: "Username",
      //   accessor: "username",
      //   filterable: false,
      //   Cell: (contact) => (
      //     <>
      //       <div className="d-flex align-items-center">
      //         <div className="flex-shrink-0">
      //           {
      //             // contact.row.original.image_src ? (
      //             0 ? (
      //               <img
      //                 src={
      //                   process.env.REACT_APP_API_URL +
      //                   "/images/users/" +
      //                   contact.row.original.image_src
      //                 }
      //                 alt=""
      //                 className="avatar-xxs rounded-circle"
      //               />
      //             ) : (
      //               <div className="flex-shrink-0 avatar-xs me-2">
      //                 <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
      //                   {/*{contact.row.original.name.charAt(0)}*/}
      //                   {11}
      //                 </div>
      //               </div>
      //             )
      //             // <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
      //           }
      //         </div>
      //         <div className="flex-grow-1 ms-2 name">
      //           {/*{contact.row.original.name}*/}
      //           {contact.username}
      //         </div>
      //       </div>
      //     </>
      //   ),
      // },
      {
        Header: "Token",
        accessor: "token",
        filterable: false,
        Cell: (contact) => (
            <>
              {/*<Link to={ "/account-details/" + contact.row.original.id }>*/}
                <span
                    onClick={() => {
                      const contactData = contact.row.original;
                      handleContactClick(contactData);
                      setErrors({});
                      validation.resetForm();
                    }}
                    style={{color: '#5691b6', textDecoration: 'underline', cursor: 'pointer'}}>
                  {contact.row.original.token}
                </span>
              {/*</Link>*/}
            </>
        ),
      },
      // {
      //   Header: "Token",
      //   accessor: "token",
      //   filterable: false,
      // },
      {
        Header: "Username",
        accessor: "username",
        filterable: false,
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: false,
      },
      // {
      //   Header: "Password",
      //   accessor: "password",
      //   filterable: false,
      //   // Filter: statusColumnFilter, // Use the custom filter function
      //   Cell: (contact) => (
      //       <>
      //         <span className="me-1">
      //           {contact.row.original.password.length * '•'}
      //         </span>
      //       </>
      //   ),
      // },
      {
        Header: "Phone",
        accessor: "phone",
        filterable: false,
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        // Filter: statusColumnFilter, // Use the custom filter function
        Cell: (contact) => (
            <>
              <span className={`badge bg-${contact.row.original.status === 'ban' ? 'danger' : 'success'}-subtle text-${contact.row.original.status === 'ban' ? 'danger' : 'success'} me-1`}>
                {contact.row.original.status.toUpperCase()}
              </span>
            </>
        ),
      },
      {
        Header: "Farms",
        filterable: false,
        Cell: (contact) => (
          <>
            {contact.row.original.farm_tags.map((item, key) => (
                <span
                    className="badge bg-primary-subtle text-primary me-1"
                    key={key}
                >
                &nbsp;{item}&nbsp;
              </span>
            ))}
          </>
        ),
      },
      {
        Header: "Joined",
        Cell: (contact) => (
          <>
            {/*{handleValidDate(contact.row.original.last_contacted)},{" "}*/}
            {/*{contact.joined},{" "}*/}
            {/*{contact.row.original.joined}*/}
            <small className="text-muted">
              {/*{handleValidTime(contact.row.original.last_contacted)}*/}
              {contact.row.original.joined}
            </small>
          </>
        ),
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Call">
                {/*<a href={"/account-details/" + cellProps.row.original.id}>*/}
                <Link to={ "/account-details/" + cellProps.row.original.id }>
                  <button className="btn btn-success">Open account</button>
                </Link>
                {/*</a>*/}
              </li>
              <li className="list-inline-item">
                <button
                    className="btn btn-danger"
                    onClick={() => {
                      const contactData = cellProps.row.original;
                      onClickDelete(contactData);
                      setIsEdit(false);
                    }}
                >
                  <i className="ri-delete-bin-2-line"></i>
                </button>
                {/*<UncontrolledDropdown>*/}
                {/*  <DropdownToggle*/}
                {/*    href="#"*/}
                {/*    className="btn btn-soft-secondary btn-sm dropdown"*/}
                {/*    tag="button"*/}
                {/*  >*/}
                {/*    <i className="ri-more-fill align-middle"></i>*/}
                {/*  </DropdownToggle>*/}
                {/*  <DropdownMenu className="dropdown-menu-end">*/}
                {/*    /!*<DropdownItem*!/*/}
                {/*    /!*  className="dropdown-item"*!/*/}
                {/*    /!*  href="#"*!/*/}
                {/*    /!*  onClick={() => {*!/*/}
                {/*    /!*    const contactData = cellProps.row.original;*!/*/}
                {/*    /!*    setInfo(contactData);*!/*/}
                {/*    /!*  }}*!/*/}
                {/*    /!*>*!/*/}
                {/*    /!*  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}*!/*/}
                {/*    /!*  View*!/*/}
                {/*    /!*</DropdownItem>*!/*/}
                {/*    <DropdownItem*/}
                {/*      className="dropdown-item edit-item-btn"*/}
                {/*      href="#"*/}
                {/*      onClick={() => {*/}
                {/*        const contactData = cellProps.row.original;*/}
                {/*        handleContactClick(contactData);*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}*/}
                {/*      Edit*/}
                {/*    </DropdownItem>*/}
                {/*    <DropdownItem*/}
                {/*      className="dropdown-item remove-item-btn"*/}
                {/*      href="#"*/}
                {/*      onClick={() => {*/}
                {/*        const contactData = cellProps.row.original;*/}
                {/*        onClickDelete(contactData);*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}*/}
                {/*      Delete*/}
                {/*    </DropdownItem>*/}
                {/*  </DropdownMenu>*/}
                {/*</UncontrolledDropdown>*/}
              </li>
            </ul>
          );
        },
      },
    ],
    [handleContactClick, checkedAll]
  );

  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  function handlestag(tags) {
    setTag(tags);
  }

  const tags = [
    { label: "Working", value: "Working" },
    { label: "Ban", value: "Ban" },
  ];

  // SideBar Contact Deatail
  const [info, setInfo] = useState([]);

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = "Accounts";
  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          // data={crmcontacts}
          data={[]}
        />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteContact}
          onCloseClick={() => setDeleteModal(false)}
        />

        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />

        <AddToFarmModal
            show={addToFarmModalMulti}
            // show={false}
            onAddToFarmClick={(id, operation) => {
              manageAccountsMultiple(id, operation);
              setAddToFarmModalMulti(false);
            }}
            onCloseClick={() => setAddToFarmModalMulti(false)}
            farms={farms}
        />
        <DeleteFromFarmModal
            show={deleteFromFarmModalMulti}
            // show={false}
            onDeleteFromFarmClick={(id, operation) => {
              manageAccountsMultiple(id, operation);
              setDeleteFromFarmModalMulti(false);
            }}
            onCloseClick={() => setDeleteFromFarmModalMulti(false)}
            farms={farms}
        />
        <ImportTokensModal
            show={importTokensModal}
            onImportClick={(tokens) => {
              importTokens(tokens);
              setImportTokensModal(false);
            }}
            onCloseClick={() => setImportTokensModal(false)}
            maxCharactersPerLine={72}
        />
        <Container fluid>
          <div style={{display: "flex"}}>
            <Link to="/farms" style={{width: "30px"}}>
              <i className="ri-reply-fill"></i>
            </Link>
            <h5>{farm?.name ? "FARM - " + farm.name : "ACCOUNTS"}</h5>
          </div>

          <Row>
            {farmId ? <WidgetsTask /> : null}
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-3">
                      <button
                        className="btn btn-info add-btn"
                        onClick={() => {
                          setModal(true);
                          setIsEdit(false);
                          setContact(null);
                          setErrors({});
                          validation.resetForm();
                        }}
                      >
                        <i className="ri-add-fill me-1 align-bottom"></i> Add new account
                      </button>
                    </div>
                    <div className="flex-grow-2">
                      {isMultiDeleteButton && (
                          <button
                              className="btn btn-success add-btn me-1-bin-2-line"
                              onClick={() => {
                                setAddToFarmModalMulti(true);
                              }}
                          >
                            Add to Farm
                          </button>
                      )}
                      {/* {isMultiDeleteButton && <button className="btn btn-secondary" onClick={() => setDeleteModalMulti(true)}></button>} */}
                    </div>
                    <div className="flex-grow-1">
                      {isMultiDeleteButton && (
                          <button
                              className="btn btn-danger add-btn me-1-bin-2-line"
                              onClick={() => {
                                setDeleteFromFarmModalMulti(true);
                              }}
                          >
                            Delete from Farm
                          </button>
                      )}
                      {/* {isMultiDeleteButton && <button className="btn btn-secondary" onClick={() => setDeleteModalMulti(true)}></button>} */}
                    </div>
                    <div className="d-flex flex-wrap gap-2">
                      <button
                          className="btn btn-success add-btn me-1-bin-2-line"
                          onClick={() => {
                            setImportTokensModal(true);
                          }}
                      >
                        Import tokens
                      </button>
                    </div>
                    <div className="d-flex flex-wrap gap-2">
                      {isMultiDeleteButton && (
                        <button
                          className="btn btn-secondary add-btn me-1-bin-2-line"
                          onClick={() => {
                            setIsEdited(false);
                            toggleTask();
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i>{" "}
                          Create Task
                        </button>
                      )}
                      {/* {isMultiDeleteButton && <button className="btn btn-secondary" onClick={() => setDeleteModalMulti(true)}></button>} */}
                    </div>
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        {isMultiDeleteButton && (
                          <button
                            className="btn btn-danger"
                            onClick={() => setDeleteModalMulti(true)}
                          >
                            <i className="ri-delete-bin-2-line"></i>
                          </button>
                        )}

                        <button className="btn btn-outline-success">
                          <i className="ri-filter-2-line me-1 align-bottom"></i>{" "}
                          Filters
                        </button>
                        {/*<button*/}
                        {/*  className="btn btn-soft-success"*/}
                        {/*  onClick={() => setIsExportCSV(true)}*/}
                        {/*>*/}
                        {/*  Export*/}
                        {/*</button>*/}

                        {/*<UncontrolledDropdown>*/}
                        {/*  <DropdownToggle*/}
                        {/*    href="#"*/}
                        {/*    className="btn btn-soft-info"*/}
                        {/*    tag="button"*/}
                        {/*  >*/}
                        {/*    <i className="ri-more-2-fill"></i>*/}
                        {/*  </DropdownToggle>*/}
                        {/*  <DropdownMenu className="dropdown-menu-end">*/}
                        {/*    <DropdownItem className="dropdown-item" href="#">*/}
                        {/*      All*/}
                        {/*    </DropdownItem>*/}
                        {/*    <DropdownItem className="dropdown-item" href="#">*/}
                        {/*      Last Week*/}
                        {/*    </DropdownItem>*/}
                        {/*    <DropdownItem className="dropdown-item" href="#">*/}
                        {/*      Last Month*/}
                        {/*    </DropdownItem>*/}
                        {/*    <DropdownItem className="dropdown-item" href="#">*/}
                        {/*      Last Year*/}
                        {/*    </DropdownItem>*/}
                        {/*  </DropdownMenu>*/}
                        {/*</UncontrolledDropdown>*/}
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col xxl={9} style={{ width: "100%" }}>
              <Card id="contactList">
                <CardBody className="pt-0">
                  <div>
                    {/*{isContactSuccess && crmcontacts.length ? (*/}
                    {accounts ? (
                      <TableContainer
                        columns={columns}
                        // data={crmcontacts || []}
                        data={accounts || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={8}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        handleContactClick={handleContactClicks}
                        isContactsFilter={true}
                        SearchPlaceholder="Search for account..."
                      />
                    ) : (
                      // <Loader error={error} />
                      <Loader error={'Unexpected error displaying accounts'} />
                    )}
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
                      {!!isEdit ? "Edit Account" : "Add New Account"}
                    </ModalHeader>

                    <Form
                      className="tablelist-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <Input type="hidden" id="id-field" />
                        <Row className="g-3">
                          <Col lg={12}>
                            <div>
                              <Label
                                  htmlFor="token_name-field"
                                  className="form-label"
                              >
                                Token
                              </Label>
                              <Input
                                  name="token"
                                  id="token_name-field"
                                  className="form-control"
                                  placeholder="Enter Token Name"
                                  type="text"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.token || ""}
                                  invalid={
                                    validation.touched.token &&
                                    validation.errors.token
                                        ? true
                                        : !!errors?.token
                                  }
                              />
                              {validation.touched.token &&
                              validation.errors.token ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.token}
                                  </FormFeedback>
                              ) : errors && errors?.token ? (
                                  <FormFeedback type="invalid"><div>{errors.token}</div></FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={12}>
                            {/*<div className="text-center">*/}
                            {/*  <div className="position-relative d-inline-block">*/}
                            {/*    <div className="position-absolute  bottom-0 end-0">*/}
                            {/*      <Label*/}
                            {/*        htmlFor="customer-image-input"*/}
                            {/*        className="mb-0"*/}
                            {/*      >*/}
                            {/*        <div className="avatar-xs cursor-pointer">*/}
                            {/*          <div className="avatar-title bg-light border rounded-circle text-muted">*/}
                            {/*            <i className="ri-image-fill"></i>*/}
                            {/*          </div>*/}
                            {/*        </div>*/}
                            {/*      </Label>*/}
                            {/*      <Input*/}
                            {/*        className="form-control d-none"*/}
                            {/*        id="customer-image-input"*/}
                            {/*        type="file"*/}
                            {/*        accept="image/png, image/gif, image/jpeg"*/}
                            {/*        onChange={validation.handleChange}*/}
                            {/*        onBlur={validation.handleBlur}*/}
                            {/*        value={validation.values.img || ""}*/}
                            {/*        invalid={*/}
                            {/*          validation.touched.img &&*/}
                            {/*          validation.errors.img*/}
                            {/*            ? true*/}
                            {/*            : false*/}
                            {/*        }*/}
                            {/*      />*/}
                            {/*    </div>*/}
                            {/*    <div className="avatar-lg p-1">*/}
                            {/*      <div className="avatar-title bg-light rounded-circle">*/}
                            {/*        <img*/}
                            {/*          src={dummyImg}*/}
                            {/*          alt="dummyImg"*/}
                            {/*          id="customer-img"*/}
                            {/*          className="avatar-md rounded-circle object-fit-cover"*/}
                            {/*        />*/}
                            {/*      </div>*/}
                            {/*    </div>*/}
                            {/*  </div>*/}
                            {/*</div>*/}

                            <div>
                              <Label
                                htmlFor="name-field"
                                className="form-label"
                              >
                                Username
                              </Label>
                              <Input
                                name="username"
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter Username"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.username || ""}
                                invalid={
                                  validation.touched.username &&
                                  validation.errors.username
                                    ? true
                                    : !!errors?.username
                                }
                              />
                              {validation.touched.username &&
                              validation.errors.username ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.username}
                                </FormFeedback>
                              ) : errors && errors?.username ? (
                                  <FormFeedback type="invalid"><div>{errors.username}</div></FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div>
                              <Label
                                htmlFor="email_id-field"
                                className="form-label"
                              >
                                Email
                              </Label>

                              <Input
                                name="email"
                                id="email_id-field"
                                className="form-control"
                                placeholder="Enter Email"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : !!errors?.email
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : errors && errors?.email ? (
                                  <FormFeedback type="invalid"><div>{errors.email}</div></FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="phone-field"
                                className="form-label"
                              >
                                Phone
                              </Label>

                              <Input
                                name="phone"
                                id="phone-field"
                                className="form-control"
                                placeholder="Enter Number Phone"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                                invalid={
                                  validation.touched.phone &&
                                  validation.errors.phone
                                    ? true
                                    : !!errors?.phone
                                }
                              />
                              {validation.touched.phone &&
                              validation.errors.phone ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.phone}
                                </FormFeedback>
                              ) : errors && errors?.phone ? (
                                  <FormFeedback type="invalid"><div>{errors.phone}</div></FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="pass_score-field"
                                className="form-label"
                              >
                                Password
                              </Label>

                              <Input
                                name="password"
                                id="pass_score-field"
                                className="form-control"
                                placeholder="Enter Password"
                                type="password"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                invalid={
                                  validation.touched.password &&
                                  validation.errors.password
                                    ? true
                                    : !!errors?.password
                                }
                              />
                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : errors && errors?.password ? (
                                  <FormFeedback type="invalid"><div>{errors.password}</div></FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          {/*<Col lg={12}>*/}
                          {/*  <div>*/}
                          {/*    <Label*/}
                          {/*      htmlFor="taginput-choices"*/}
                          {/*      className="form-label font-size-13 text-muted"*/}
                          {/*    >*/}
                          {/*      Tags*/}
                          {/*    </Label>*/}
                          {/*    <Select*/}
                          {/*      isMulti*/}
                          {/*      value={tag}*/}
                          {/*      onChange={(e) => {*/}
                          {/*        handlestag(e);*/}
                          {/*      }}*/}
                          {/*      className="mb-0"*/}
                          {/*      options={tags}*/}
                          {/*      id="taginput-choices"*/}
                          {/*    ></Select>*/}

                          {/*    {validation.touched.tags &&*/}
                          {/*    validation.errors.tags ? (*/}
                          {/*      <FormFeedback type="invalid">*/}
                          {/*        {validation.errors.tags}*/}
                          {/*      </FormFeedback>*/}
                          {/*    ) : null}*/}
                          {/*  </div>*/}
                          {/*</Col>*/}
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                              setModal(false);
                              // setIsEdit(false);
                              setErrors({});
                              validation.resetForm();
                            }}
                          >
                            {" "}
                            Close{" "}
                          </button>
                          <button
                            type="submit"
                            className="btn btn-success"
                            id="add-btn"
                          >
                            {" "}
                            {!!isEdit ? "Update" : "Add new account"}{" "}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  {/* task modal */}
                  <Modal
                    isOpen={modalTask}
                    toggle={toggleTask}
                    centered
                    size="lg"
                    className="border-0"
                    modalClassName="modal fade zoomIn"
                  >
                    <ModalHeader
                      className="p-3 bg-info-subtle"
                      toggle={toggleTask}
                    >
                      {!!isEdited ? "Edit Task" : "Create Task"}
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        valid.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody className="modal-body">
                        <Row className="g-3">
                          <Col lg={12}>
                            <Label for="taskId-field" className="form-label">
                              Order Id
                            </Label>
                            <Input
                              name="taskId"
                              id="taskId-field"
                              className="form-control"
                              placeholder="Enter Task Id "
                              type="text"
                              validate={{
                                required: { value: true },
                              }}
                              onChange={valid.handleChange}
                              onBlur={valid.handleBlur}
                              value={valid.values.taskId || ""}
                              invalid={
                                valid.touched.taskId && valid.errors.taskId
                                  ? true
                                  : false
                              }
                            />
                            {valid.touched.taskId && valid.errors.taskId ? (
                              <FormFeedback type="invalid">
                                {valid.errors.taskId}
                              </FormFeedback>
                            ) : null}
                          </Col>

                          <Col lg={12}>
                            <Label
                              for="projectName-field"
                              className="form-label"
                            >
                              Project Name
                            </Label>
                            <Input
                              name="project"
                              id="projectName-field"
                              className="form-control"
                              placeholder="Project name"
                              type="text"
                              validate={{
                                required: { value: true },
                              }}
                              onChange={valid.handleChange}
                              onBlur={valid.handleBlur}
                              value={valid.values.project || ""}
                              invalid={
                                valid.touched.project && valid.errors.project
                                  ? true
                                  : false
                              }
                            />
                            {valid.touched.project && valid.errors.project ? (
                              <FormFeedback type="invalid">
                                {valid.errors.project}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col lg={12}>
                            <div>
                              <Label
                                for="tasksTitle-field"
                                className="form-label"
                              >
                                Title
                              </Label>
                              <Input
                                name="task"
                                id="tasksTitle-field"
                                className="form-control"
                                placeholder="Title"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={valid.handleChange}
                                onBlur={valid.handleBlur}
                                value={valid.values.task || ""}
                                invalid={
                                  valid.touched.task && valid.errors.task
                                    ? true
                                    : false
                                }
                              />
                              {valid.touched.task && valid.errors.task ? (
                                <FormFeedback type="invalid">
                                  {valid.errors.task}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={12}>
                            <Label
                              for="clientName-field"
                              className="form-label"
                            >
                              Client Name
                            </Label>
                            <Input
                              name="creater"
                              id="clientName-field"
                              className="form-control"
                              placeholder="Client name"
                              type="text"
                              validate={{
                                required: { value: true },
                              }}
                              onChange={valid.handleChange}
                              onBlur={valid.handleBlur}
                              value={valid.values.creater || ""}
                              invalid={
                                valid.touched.creater && valid.errors.creater
                                  ? true
                                  : false
                              }
                            />
                            {valid.touched.creater && valid.errors.creater ? (
                              <FormFeedback type="invalid">
                                {valid.errors.creater}
                              </FormFeedback>
                            ) : null}
                          </Col>

                          <Col lg={6}>
                            <Label for="duedate-field" className="form-label">
                              Due Date
                            </Label>

                            <Flatpickr
                              name="dueDate"
                              id="duedate-field"
                              className="form-control"
                              placeholder="Select a date"
                              options={{
                                altInput: true,
                                altFormat: "d M, Y",
                                dateFormat: "d M, Y",
                              }}
                              onChange={(e) => dateformate(e)}
                              value={valid.values.dueDate || ""}
                            />
                            {valid.touched.dueDate && valid.errors.dueDate ? (
                              <FormFeedback type="invalid">
                                {valid.errors.dueDate}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col lg={6}>
                            <Label for="ticket-status" className="form-label">
                              Status
                            </Label>
                            <Input
                              name="status"
                              type="select"
                              className="form-select"
                              id="ticket-field"
                              onChange={valid.handleChange}
                              onBlur={valid.handleBlur}
                              value={valid.values.status || ""}
                            >
                              <option value="">Status</option>
                              <option value="New">New</option>
                              <option value="Inprogress">Inprogress</option>
                              <option value="Pending">Pending</option>
                              <option value="Completed">Completed</option>
                            </Input>
                            {valid.touched.status && valid.errors.status ? (
                              <FormFeedback type="invalid">
                                {valid.errors.status}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col lg={12}>
                            <Label for="priority-field" className="form-label">
                              Priority
                            </Label>
                            <Input
                              name="priority"
                              type="select"
                              className="form-select"
                              id="priority-field"
                              onChange={valid.handleChange}
                              onBlur={valid.handleBlur}
                              value={valid.values.priority || ""}
                            >
                              <option value="">Priority</option>
                              <option value="High">High</option>
                              <option value="Medium">Medium</option>
                              <option value="Low">Low</option>
                            </Input>
                            {valid.touched.priority && valid.errors.priority ? (
                              <FormFeedback type="invalid">
                                {valid.errors.priority}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </ModalBody>
                      <div className="modal-footer">
                        <div className="hstack gap-2 justify-content-end">
                          <Button
                            type="button"
                            onClick={() => {
                              setModalTask(false);
                            }}
                            className="btn-light"
                          >
                            Close
                          </Button>
                          <button
                            type="submit"
                            className="btn btn-success"
                            id="add-btn"
                          >
                            {!!isEdit ? "Update Task" : "Add Task"}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>

            {/* <Col xxl={3}>
              <Card id="contact-view-detail">
                <CardBody className="text-center">
                  <div className="position-relative d-inline-block">
                    <img
                      src={process.env.REACT_APP_API_URL + "/images/users/" + (info.image_src || "avatar-10.jpg")}
                      alt=""
                      className="avatar-lg rounded-circle img-thumbnail"
                    />
                    <span className="contact-active position-absolute rounded-circle bg-success">
                      <span className="visually-hidden"></span>
                    </span>
                  </div>
                  <h5 className="mt-4 mb-1">{info.name || "Tonya Noble"}</h5>
                  <p className="text-muted">{info.company || "Nesta Technologies"}</p>

                  <ul className="list-inline mb-0">
                    <li className="list-inline-item avatar-xs">
                      <Link
                        to="#"
                        className="avatar-title bg-success-subtle text-success fs-15 rounded"
                      >
                        <i className="ri-phone-line"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item avatar-xs">
                      <Link
                        to="#"
                        className="avatar-title bg-danger-subtle text-danger fs-15 rounded"
                      >
                        <i className="ri-mail-line"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item avatar-xs">
                      <Link
                        to="#"
                        className="avatar-title bg-warning-subtle text-warning fs-15 rounded"
                      >
                        <i className="ri-question-answer-line"></i>
                      </Link>
                    </li>
                  </ul>
                </CardBody>
                <CardBody>
                  <h6 className="text-muted text-uppercase fw-semibold mb-3">
                    Personal Information
                  </h6>
                  <p className="text-muted mb-4">
                    Hello, I'm {info.name || "Tonya Noble"}, The most effective objective is one
                    that is tailored to the job you are applying for. It states
                    what kind of career you are seeking, and what skills and
                    experiences.
                  </p>
                  <div className="table-responsive table-card">
                    <Table className="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <td className="fw-medium">
                            Designation
                          </td>
                          <td>Lead Designer / Developer</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Email ID
                          </td>
                          <td>{info.email || "tonyanoble@velzon.com"}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Phone No
                          </td>
                          <td>{info.phone || "414-453-5725"}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Lead Score
                          </td>
                          <td>{info.lead_score || "154"}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Tags
                          </td>
                          <td>
                            {(info.tags ||
                              [
                                "Lead",
                                "Partner"
                              ]
                            ).map((item, key) => (<span className="badge bg-primary-subtle text-primary me-1" key={key}>{item}</span>))
                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Last Contacted
                          </td>
                          <td>
                            {handleValidDate(info.last_contacted || "2021-04-13T18:30:00.000Z")}{" "}
                            <small className="text-muted">{handleValidTime(info.last_contacted || "2021-04-13T18:30:00.000Z")}</small>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CrmContacts;
