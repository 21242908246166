import { APIClient } from "./api_helper";

import * as url from "./url_helper";
import {GET_FARM_ACCOUNTS, IMPORT_ACCOUNTS, IMPORT_ACCOUNTS_TO_FARM, MANAGE_ACCOUNTS} from "./url_helper";
// import {GET_AUTHENTICATED, GET_FARMS, POST_JWT_LOGIN} from "./url_helper";

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
    const user = localStorage.getItem("user");
    if (user) return JSON.parse(user);
    return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
};

// Register Method
export const postFakeRegister = data => api.create(url.POST_FAKE_REGISTER, data);

// Login Method
// export const postFakeLogin = data => api.create(url.POST_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = data => api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = data => api.create(url.POST_EDIT_JWT_PROFILE, data);

// export const postFakeProfile = (data) => api.update(url.POST_EDIT_PROFILE + '/' + data.idx, data);
export const postEditProfile = (data) => api.update(url.GET_AUTHENTICATED, data);
export const postPasswordChange = (data) => api.create(url.POST_PASSWORD_CHANGE, data);

// Register Method
export const postJwtRegister = (url, data) => {
    return api.create(url, data)
        .catch(err => {
            var message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        message = "Sorry! the page you are looking for could not be found";
                        break;
                    case 500:
                        message = "Sorry! something went wrong, please contact our support team";
                        break;
                    case 401:
                        message = "Invalid credentials";
                        break;
                    default:
                        message = err[1];
                        break;
                }
            }
            throw message;
        });
};

// FARMS
export const getFarm = data => api.get(url.GET_FARMS + data.id, data);
export const getFarmsApi = data => api.get(url.GET_FARMS, data);
export const createFarmApi = data => api.create(url.GET_FARMS, data);
export const updateFarmApi = data => api.update(url.GET_FARMS + data.id + '/', data);
export const deleteFarmApi = data => api.delete(url.GET_FARMS + data.id + '/', data);
export const manageAccountsApi = data => api.create(url.MANAGE_ACCOUNTS, data);

// ACCOUNTS
export const getAccountsApi = data => api.get(url.GET_ACCOUNTS, data);
export const getFarmAccountsApi = data => api.get(url.GET_FARM_ACCOUNTS + data.farmId + '/accounts', data);
export const getAccountDetailApi = data => api.get(url.GET_ACCOUNTS + data.id + '/', data);
export const createAccountApi = data => api.create(url.GET_ACCOUNTS, data);
export const createFarmAccountApi = data => api.create(url.GET_FARM_ACCOUNTS + data.farmId + '/accounts/', data);
export const updateAccountApi = data => api.update(url.GET_ACCOUNTS + data.id + '/', data);
export const deleteAccountApi = data => api.delete(url.GET_ACCOUNTS + data.id + '/', data);

export const importTokensApi = data => api.create(url.IMPORT_ACCOUNTS, data);
export const importTokensFarmApi = data => api.create(url.IMPORT_ACCOUNTS_TO_FARM + data.farmId + '/accounts/import-tokens/', data);


// Login Method
export const postJwtLogin = data => api.create(url.POST_JWT_LOGIN, data);
export const getAuthenticated = data => api.get(url.GET_AUTHENTICATED, data);

// Register
export const postJwtRegisterCustom = data => api.create(url.POST_JWT_REGISTER, data);
export const postJwtConfirmEmail = data => api.create(url.POST_JWT_CONFIRM_EMAIL, data);

// postForgetPwd
export const postJwtForgetPwd = data => api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => api.create(url.SOCIAL_LOGIN, data);

//API Key
export const getAPIKey = () => api.get(url.GET_API_KEY);