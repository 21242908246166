import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isBaseUi, setIsBaseUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);

  // Apps
  const [isEmail, setEmail] = useState(false);
  const [isSubEmail, setSubEmail] = useState(false);
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [isProjects, setIsProjects] = useState(false);
  const [isTasks, setIsTasks] = useState(false);
  const [isCRM, setIsCRM] = useState(false);
  const [isCrypto, setIsCrypto] = useState(false);
  const [isInvoices, setIsInvoices] = useState(false);
  const [isSupportTickets, setIsSupportTickets] = useState(false);
  const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);
  const [isJobs, setIsJobs] = useState(false);
  const [isJobList, setIsJobList] = useState(false);
  const [isCandidateList, setIsCandidateList] = useState(false);

  // Authentication
  const [isSignIn, setIsSignIn] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isPasswordCreate, setIsPasswordCreate] = useState(false);
  const [isLockScreen, setIsLockScreen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isVerification, setIsVerification] = useState(false);
  const [isError, setIsError] = useState(false);

  // Pages
  const [isProfile, setIsProfile] = useState(false);
  const [isLanding, setIsLanding] = useState(false);

  // Charts
  const [isApex, setIsApex] = useState(false);

  // Multi Level
  const [isLevel1, setIsLevel1] = useState(false);
  const [isLevel2, setIsLevel2] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }
    if (iscurrentState !== "Pages") {
      setIsPages(false);
    }
   
    if (iscurrentState !== "Forms") {
      setIsForms(false);
    }
    if (iscurrentState !== "Tables") {
      setIsTables(false);
    }
    
    if (iscurrentState !== "Icons") {
      setIsIcons(false);
    }
    if (iscurrentState !== "Maps") {
      setIsMaps(false);
    }
    if (iscurrentState !== "MuliLevel") {
      setIsMultiLevel(false);
    }
    if (iscurrentState === "Widgets") {
      history("/widgets");
      document.body.classList.add("twocolumn-panel");
    }
    if (iscurrentState !== "Landing") {
      setIsLanding(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isApps,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel,
  ]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    // {
    //   id: "dashboard",
    //   label: "Dashboards",
    //   icon: "bx bxs-dashboard",
    //   link: "/#",
    //   stateVariables: isDashboard,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsDashboard(!isDashboard);
    //     setIscurrentState("Dashboard");
    //     updateIconSidebar(e);
    //   },
   
    // },
    // {
    //   id: "apps",
    //   label: "Apps",
    //   icon: "bx bx-layer",
    //   link: "/#",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsApps(!isApps);
    //     setIscurrentState("Apps");
    //     updateIconSidebar(e);
    //   },
    //   stateVariables: isApps,
    //    [

        {
          id: "farms",
          icon: "ri-stack-fill",
          label: "Farms",
          link: "/farms",
        },
        // {
        //   id: "servers",
        //   label: "Servers",
        //   link: "/servers",
        //   parentId: "apps",
        // },
        {
          id: "accounts",
          icon: "ri-file-user-fill",
          label: "Accounts",
          link: "/all-accounts",
        },
        {
          id: "tasks",
          label: "Tasks",
          icon: "ri-terminal-box-line",
          link: "/#",
          click: function (e) {
            e.preventDefault();
            setIsTasks(!isTasks);
          },
          parentId: "apps",
          stateVariables: isTasks,
          subItems: [
            {
              id: 1,
              label: "All Tasks",
              link: "/all-tasks",
            },
            {
              id: 2,
              label: "Task Details",
              link: "/apps-tasks-details",
            },
          ],
        },
       
    
        // {
        //   id: "accounts",
        //   label: "Accounts", apps-crm-contacts
        //   link: "/apps-crm-contacts",
        //   parentId: "apps",
        // },
        // {
        //     id: "profile",
        //     icon: "ri-settings-5-fill",
        //     label: "Profile Settings",
        //     link: "/pages-profile-settings",
        // },
        // {
        //     id: "faqs",
        //     icon: " ri-question-line",
        //     label: "FAQ",
        //     link: "/pages-faqs",
        // },
        // {
        //     id: "pricing",
        //     icon: "ri-bank-card-2-fill",
        //     label: "Pricing",
        //     link: "/pages-pricing",
        // },
    
      
    //   ],
    // },
 
    

    // {
    //   id: "widgets",
    //   label: "Widgets",
    //   icon: "bx bx-aperture",
    //   link: "/widgets",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIscurrentState("Widgets");
    //   },
    // },
    // {
    //   id: "forms",
    //   label: "Forms",
    //   icon: "bx bx-receipt",
    //   link: "/#",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsForms(!isForms);
    //     setIscurrentState("Forms");
    //     updateIconSidebar(e);
    //   },
    //   stateVariables: isForms,
    //   subItems: [
    //     {
    //       id: "basicelements",
    //       label: "Basic Elements",
    //       link: "/forms-elements",
    //       parentId: "forms",
    //     },
    //     {
    //       id: "formselect",
    //       label: "Form Select",
    //       link: "/forms-select",
    //       parentId: "forms",
    //     },
    //     {
    //       id: "checkboxsradios",
    //       label: "Checkboxs & Radios",
    //       link: "/forms-checkboxes-radios",
    //       parentId: "forms",
    //     },
    //     {
    //       id: "pickers",
    //       label: "Pickers",
    //       link: "/forms-pickers",
    //       parentId: "forms",
    //     },
    //     {
    //       id: "inputmasks",
    //       label: "Input Masks",
    //       link: "/forms-masks",
    //       parentId: "forms",
    //     },
    //     {
    //       id: "advanced",
    //       label: "Advanced",
    //       link: "/forms-advanced",
    //       parentId: "forms",
    //     },
    //     {
    //       id: "rangeslider",
    //       label: "Range Slider",
    //       link: "/forms-range-sliders",
    //       parentId: "forms",
    //     },
    //     {
    //       id: "validation",
    //       label: "Validation",
    //       link: "/forms-validation",
    //       parentId: "forms",
    //     },
    //     {
    //       id: "wizard",
    //       label: "Wizard",
    //       link: "/forms-wizard",
    //       parentId: "forms",
    //     },
    //     {
    //       id: "editors",
    //       label: "Editors",
    //       link: "/forms-editors",
    //       parentId: "forms",
    //     },
    //     {
    //       id: "fileuploads",
    //       label: "File Uploads",
    //       link: "/forms-file-uploads",
    //       parentId: "forms",
    //     },
    //     {
    //       id: "formlayouts",
    //       label: "Form Layouts",
    //       link: "/forms-layouts",
    //       parentId: "forms",
    //     },
    //     {
    //       id: "select2",
    //       label: "Select2",
    //       link: "/forms-select2",
    //       parentId: "forms",
    //     },
    //   ],
    // },
    // {
    //   id: "tables",
    //   label: "Tables",
    //   icon: "bx bx-table",
    //   link: "/#",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsTables(!isTables);
    //     setIscurrentState("Tables");
    //     updateIconSidebar(e);
    //   },
    //   stateVariables: isTables,
    //   subItems: [
    //     {
    //       id: "basictables",
    //       label: "Basic Tables",
    //       link: "/tables-basic",
    //       parentId: "tables",
    //     },

    //     {
    //       id: "listjs",
    //       label: "List Js",
    //       link: "/tables-listjs",
    //       parentId: "tables",
    //     },

    //     {
    //       id: "reactdatatables",
    //       label: "React Datatables",
    //       link: "/tables-react",
    //       parentId: "tables",
    //     },
    //   ],
    // },
   
    // {
    //   id: "icons",
    //   label: "Icons",
    //   icon: "ri-compasses-2-line",
    //   link: "/#",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsIcons(!isIcons);
    //     setIscurrentState("Icons");
    //     updateIconSidebar(e);
    //   },
    //   stateVariables: isIcons,
    //   subItems: [
    //     {
    //       id: "remix",
    //       label: "Remix",
    //       link: "/icons-remix",
    //       parentId: "icons",
    //     },
    //     {
    //       id: "boxicons",
    //       label: "Boxicons",
    //       link: "/icons-boxicons",
    //       parentId: "icons",
    //     },
    //     {
    //       id: "materialdesign",
    //       label: "Material Design",
    //       link: "/icons-materialdesign",
    //       parentId: "icons",
    //     },
    //     {
    //       id: "lineawesome",
    //       label: "Line Awesome",
    //       link: "/icons-lineawesome",
    //       parentId: "icons",
    //     },
    //     {
    //       id: "feather",
    //       label: "Feather",
    //       link: "/icons-feather",
    //       parentId: "icons",
    //     },
    //     {
    //       id: "crypto",
    //       label: "Crypto SVG",
    //       link: "/icons-crypto",
    //       parentId: "icons",
    //     },
    //   ],
    // },
  
   
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
