import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import CountUp from "react-countup";
// import { taskWidgets } from '../../common/data';
import { Link } from 'react-router-dom';

const taskWidgets = [
    {
        id: 1,
        label: "Total Tasks",
        counter: "0",
        badge: "ri-arrow-up-line",
        badgeClass: "success",
        // percentage: "17.32 %",
        icon: "ri-ticket-2-line",
        iconClass: "info",
        decimals: 1,
        prefix: "",
        // suffix: "k",
    },
    {
        id: 2,
        label: "Pending Tasks",
        counter: "0",
        badge: "ri-arrow-down-line",
        badgeClass: "danger",
        // percentage: "0.87 %",
        icon: "mdi mdi-timer-sand",
        iconClass: "warning",
        decimals: 1,
        prefix: "",
        // suffix: "k",
    },
    {
        id: 3,
        label: "Completed Tasks",
        counter: "0",
        badge: "ri-arrow-down-line",
        badgeClass: "danger",
        // percentage: "2.52 %",
        icon: "ri-checkbox-circle-line",
        iconClass: "success",
        decimals: 2,
        prefix: "",
        // suffix: "K",
    },
    {
        id: 4,
        label: "Deleted Tasks",
        counter: "0",
        badge: "ri-arrow-up-line",
        badgeClass: "success",
        // percentage: "0.63 %",
        icon: "ri-delete-bin-line",
        iconClass: "danger",
        decimals: 2,
        prefix: "$",
        // suffix: "%",
    },
];

const WidgetsTask = () => {
    return (
        <React.Fragment>
            {taskWidgets.map((item, key) => (
                <Col xxl={3} sm={6} key={key}>
                    <Link to="/apps-tasks-list-view">
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">{item.label}</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                        <span className="counter-value">
                                            <CountUp
                                                start={0}
                                                end={item.counter}
                                                decimal={item.decimals}
                                                suffix={item.suffix}
                                                duration={3}
                                            />
                                        </span>
                                    </h2>
                                    <p className="mb-0 text-muted">
                                        {/*<span className={"badge bg-light mb-0 text-" + item.badgeClass}>*/}
                                        {/*    <i className={"align-middle " + item.badge}></i> {item.percentage}*/}
                                        {/*</span> vs. previous month*/}
                                    </p>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className={"avatar-title rounded-circle fs-4 bg-" + item.iconClass + "-subtle  text-" + item.iconClass}>
                                            <i className={item.icon}></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    </Link>
                </Col>
            ))}
        </React.Fragment>
    );
};

export default WidgetsTask;