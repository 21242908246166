import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container, DropdownItem, DropdownMenu, DropdownToggle,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row, UncontrolledDropdown,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
// import SimpleBar from "simplebar-react";
import {Link, useNavigate, useParams} from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";
import styles from "./Servers.module.css";

import {getAccountDetailApi} from "../../helpers/backend_helper";
import TableContainer from "../../Components/Common/TableContainer";
import Loader from "../../Components/Common/Loader";


const ListTables = () => {
  const navigate = useNavigate(); // Get the navigate function
  document.title = "Account Details";

  const { id } = useParams();

  const [account, setAccount] = useState({});
  const [servers, setServers] = useState([]);

  useEffect(() => {
    // Fetch farms when the component mounts
    getAccountDetailApi({id})
        .then((data) => {
          setAccount(data);
          setServers(data.servers);
        })
        .catch((error) => {
          // console.error('Error fetching account:', error);
          navigate('/all-accounts');
        });
  }, []);

  const columns = useMemo(
      () => [
        // {
        //   Header: (
        //       <input
        //           type="checkbox"
        //           id="checkBoxAll"
        //           className="form-check-input"
        //           onClick={() => checkedAll()}
        //       />
        //   ),
        //   Cell: (cellProps) => {
        //     return (
        //         <input
        //             type="checkbox"
        //             className="contactCheckBox form-check-input"
        //             value={cellProps.row.original._id}
        //             onChange={() => deleteCheckbox()}
        //         />
        //     );
        //   },
        //   id: "#",
        // },
        // {
        //   Header: "Username",
        //   accessor: "username",
        //   filterable: false,
        //   Cell: (contact) => (
        //     <>
        //       <div className="d-flex align-items-center">
        //         <div className="flex-shrink-0">
        //           {
        //             // contact.row.original.image_src ? (
        //             0 ? (
        //               <img
        //                 src={
        //                   process.env.REACT_APP_API_URL +
        //                   "/images/users/" +
        //                   contact.row.original.image_src
        //                 }
        //                 alt=""
        //                 className="avatar-xxs rounded-circle"
        //               />
        //             ) : (
        //               <div className="flex-shrink-0 avatar-xs me-2">
        //                 <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
        //                   {/*{contact.row.original.name.charAt(0)}*/}
        //                   {11}
        //                 </div>
        //               </div>
        //             )
        //             // <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
        //           }
        //         </div>
        //         <div className="flex-grow-1 ms-2 name">
        //           {/*{contact.row.original.name}*/}
        //           {contact.username}
        //         </div>
        //       </div>
        //     </>
        //   ),
        // },
        // {
        //   Header: "Token",
        //   accessor: "token",
        //   filterable: false,
        //   Cell: (contact) => (
        //       <>
        //         <a href={ "/account-details/" + contact.row.original.id } style={{color: '#5691b6', textDecoration: 'underline'}}>
        //           {contact.row.original.token}
        //         </a>
        //       </>
        //   ),
        // },
        {
          Header: "Name",
          accessor: "name",
          filterable: false,
        },
        {
          Header: "Verification",
          accessor: "verification",
          filterable: true,
          Cell: (contact) => (
              <>
              <span className={`badge bg-${contact.row.original.verification ? 'success' : 'danger'}-subtle text-${contact.row.original.verification ? 'success' : 'danger'} me-1`}>
                {contact.row.original.verification ? "VERIFIED" : "NOT VERIFIED"}
              </span>
              </>
          ),
        },
        {
          Header: "Accept Rules",
          accessor: "accept_rules",
          filterable: false,
          Cell: (contact) => (
              <>
              <span className={`badge bg-${contact.row.original.accept_rules ? 'success' : 'danger'}-subtle text-${contact.row.original.accept_rules ? 'success' : 'danger'} me-1`}>
                {contact.row.original.accept_rules ? "ACCEPTED" : "NOT ACCEPTED"}
              </span>
              </>
          ),
        },
        {
          Header: "Roles",
          filterable: false,
          Cell: (contact) => (
              <>
                {contact.row.original.roles.map((item, key) => (
                    <span
                        className="badge bg-primary-subtle text-primary me-1"
                        key={key}
                    >
                &nbsp;{item}&nbsp;
              </span>
                ))}
              </>
          ),
        },
        // {
        //   Header: "Joined",
        //   accessor: "joined",
        //   filterable: false,
        // },
        {
          Header: "Joined",
          Cell: (contact) => (
              <>
                {/*{handleValidDate(contact.row.original.last_contacted)},{" "}*/}
                {/*{contact.joined},{" "}*/}
                {/*{contact.row.original.joined}*/}
                <small className="text-muted">
                  {/*{handleValidTime(contact.row.original.last_contacted)}*/}
                  {contact.row.original.joined}
                </small>
              </>
          ),
        },
        // {
        //   Header: "Action",
        //   Cell: (cellProps) => {
        //     return (
        //         <ul className="list-inline hstack gap-2 mb-0">
        //           <li className="list-inline-item edit" title="Call">
        //             <a href={"/account-details/" + cellProps.row.original.id}>
        //               <button className="btn btn-success">Open account</button>
        //             </a>
        //           </li>
        //           <li className="list-inline-item">
        //             <UncontrolledDropdown>
        //               <DropdownToggle
        //                   href="#"
        //                   className="btn btn-soft-secondary btn-sm dropdown"
        //                   tag="button"
        //               >
        //                 <i className="ri-more-fill align-middle"></i>
        //               </DropdownToggle>
        //               <DropdownMenu className="dropdown-menu-end">
        //                 {/*<DropdownItem*/}
        //                 {/*  className="dropdown-item"*/}
        //                 {/*  href="#"*/}
        //                 {/*  onClick={() => {*/}
        //                 {/*    const contactData = cellProps.row.original;*/}
        //                 {/*    setInfo(contactData);*/}
        //                 {/*  }}*/}
        //                 {/*>*/}
        //                 {/*  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}*/}
        //                 {/*  View*/}
        //                 {/*</DropdownItem>*/}
        //                 <DropdownItem
        //                     className="dropdown-item edit-item-btn"
        //                     href="#"
        //                     onClick={() => {
        //                       const contactData = cellProps.row.original;
        //                       handleContactClick(contactData);
        //                     }}
        //                 >
        //                   <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
        //                   Edit
        //                 </DropdownItem>
        //                 <DropdownItem
        //                     className="dropdown-item remove-item-btn"
        //                     href="#"
        //                     onClick={() => {
        //                       const contactData = cellProps.row.original;
        //                       onClickDelete(contactData);
        //                     }}
        //                 >
        //                   <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
        //                   Delete
        //                 </DropdownItem>
        //               </DropdownMenu>
        //             </UncontrolledDropdown>
        //           </li>
        //         </ul>
        //     );
        //   },
        // },
      ],
      []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <div style={{display: "flex"}}>
            <Link to="/all-accounts" style={{width: "30px"}}>
              <i className="ri-reply-fill"></i>
            </Link>
            <h5>SERVERS</h5>
          </div>
          
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {servers ? (
                      <TableContainer
                          columns={columns}
                          data={servers || []}
                          isGlobalFilter={true}
                          isAddUserList={false}
                          customPageSize={8}
                          className="custom-header-css"
                          divClass="table-responsive table-card mb-3"
                          tableClass="align-middle table-nowrap"
                          theadClass="table-light"
                          // handleContactClick={handleContactClicks}
                          isContactsFilter={true}
                          SearchPlaceholder="Search for server..."
                      />
                  ) : (
                      // <Loader error={error} />
                      <Loader error={'Unexpected error displaying account details'} />
                  )}

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ListTables;