import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import { Modal, ModalBody, Input } from "reactstrap";

const DeleteFromFarmModal = ({ show, farms, onDeleteFromFarmClick, onCloseClick }) => {
    const [selectedFarmId, setSelectedFarmId] = useState('');
    const [error, setError] = useState(null);

    const handleAddToFarmClick = () => {
        if (selectedFarmId) {
            // The user has selected a farm, proceed with the action.
            onDeleteFromFarmClick(
                Number(selectedFarmId),
                'remove'
            );
            // Clear the error message, if any.
            setError(null);
        } else {
            // The user has not selected a farm, display an error message.
            setError('Please select a farm.');
        }
    };

    useEffect(() => {
        if (show) {
            setError(null); // Clear the error when the modal is opened.
            setSelectedFarmId('');
        }
    }, [show]);

    return (
        <Modal isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalBody className="py-3 px-5">
                <div className="mt-2 text-center">
                    <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                        <lord-icon
                            src="https://cdn.lordicon.com/gsqxdxog.json"
                            trigger="loop"
                            colors="primary:#324189,secondary:#f56521"
                            style={{ width: "100px", height: "100px" }}
                        ></lord-icon>
                        <h4>Delete from Farm</h4>
                        <p className="text-muted mx-4 mb-0">
                            Chose a Farm to delete accounts from
                        </p>
                    </div>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button
                        type="button"
                        className="btn w-sm btn-light"
                        data-bs-dismiss="modal"
                        onClick={onCloseClick}
                    >
                        Close
                    </button>
                    <div>
                        <select
                            className="form-select"
                            value={selectedFarmId}
                            onChange={(e) => setSelectedFarmId(e.target.value)}
                        >
                            <option value="" disabled>
                                Select a Farm
                            </option>
                            {farms.map((farm) => (
                                <option key={farm.id} value={farm.id}>
                                    {farm.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button
                        type="button"
                        className="btn w-sm btn-danger"
                        onClick={handleAddToFarmClick} // Use the modified function
                    >
                        Delete from Farm
                    </button>
                </div>
                {error && <div className="text-danger text-center">{error}</div>}
            </ModalBody>
        </Modal>
    );
};

DeleteFromFarmModal.propTypes = {
    show: PropTypes.bool.isRequired,
    farms: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    onDeleteFromFarmClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
};

export default DeleteFromFarmModal;