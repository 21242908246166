import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import { Modal, ModalBody, Input } from "reactstrap";

const AddToFarmModal = ({ show, farms, onAddToFarmClick, onCloseClick }) => {
  const [selectedFarmId, setSelectedFarmId] = useState('');
  const [error, setError] = useState(null);

  const handleAddToFarmClick = () => {
    if (selectedFarmId) {
      // The user has selected a farm, proceed with the action.
      onAddToFarmClick(
          Number(selectedFarmId),
          'add'
      );
      // Clear the error message, if any.
      setError(null);
    } else {
      // The user has not selected a farm, display an error message.
      setError('Please select a farm before adding accounts.');
    }
  };

  useEffect(() => {
    if (show) {
      setError(null); // Clear the error when the modal is opened.
      setSelectedFarmId('');
    }
  }, [show]);

  return (
      <Modal isOpen={show} toggle={onCloseClick} centered={true}>
        <ModalBody className="py-3 px-5">
          <div className="mt-2 text-center">
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Add to Farm</h4>
              <p className="text-muted mx-4 mb-0">
                Chose a Farm to add selected accounts
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
                type="button"
                className="btn w-sm btn-light"
                data-bs-dismiss="modal"
                onClick={onCloseClick}
            >
              Close
            </button>
            <div>
              <select
                  className="form-select"
                  value={selectedFarmId}
                  onChange={(e) => setSelectedFarmId(e.target.value)}
              >
                <option value="" disabled>
                  Select a Farm
                </option>
                {farms.map((farm) => (
                    <option key={farm.id} value={farm.id}>
                      {farm.name}
                    </option>
                ))}
              </select>
            </div>
            <button
                type="button"
                className="btn w-sm btn-success"
                onClick={handleAddToFarmClick} // Use the modified function
            >
              Add to farm
            </button>
          </div>
          {error && <div className="text-danger text-center">{error}</div>}
        </ModalBody>
      </Modal>
  );
};

AddToFarmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  farms: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
  ).isRequired,
  onAddToFarmClick: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};

export default AddToFarmModal;