import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import { Modal, ModalBody } from "reactstrap";
import {toast} from "react-toastify";

const ImportTokensModal = ({ show, onImportClick, onCloseClick, maxCharactersPerLine }) => {
  const [tokens, setTokens] = useState('');
  const [error, setError] = useState(null);
  const [exceededLineLimit, setExceededLineLimit] = useState(false);

  const textareaRef = useRef(null);

  const handleInputChange = async (e) => {
    const lines = e.target.value.split('\n');

    // Remove empty lines except for the last one
    const filteredLines = lines.filter((line, index) => {
      // Keep the last line empty, or any line that has content
      return index === lines.length - 1 || line.trim() !== '';
    });

    // Truncate lines to the maximum character limit
    const truncatedLines = filteredLines.map((line) =>
        line.slice(0, maxCharactersPerLine)
    );

    // Limit the number of lines to a maximum of 100
    if (truncatedLines.length > 100) {
      truncatedLines.splice(100);
      setExceededLineLimit(true); // Set the state variable when the limit is exceeded
    } else {
      setExceededLineLimit(false); // Reset the state variable when the line count is within the limit
    }

    const newTokens = truncatedLines.join('\n');

    textareaRef.current.style.height = 20 + 'px';
    await setTokens(newTokens);
    textareaRef.current.style.height = Math.max(
        textareaRef.current.scrollHeight + 3,
        50
    ) + 'px';
  };

  const validateTokens = (input) => {
    // Define a regular expression pattern to allow only alphanumeric characters and whitespace.
    const pattern = /^[a-zA-Z\d\n.-]*$/;
    const test = pattern.test(input);

    if (!input || !test){
      setError("Invalid tokens. Please provide one-per-line tokens for import. No special characters allowed.")
      return false;
    }
    // count invalids
    let arrInvalid = [];
    let count = 1;
    for (const token of input.split('\n')) {
      if (token.length < 70 || token.length > maxCharactersPerLine){
        arrInvalid.push(count);
      }
      count += 1;
    }
    // check length
    if (arrInvalid.length > 0){
      setError('Tokens on positions [' + String(arrInvalid) + '] are invalid');
      return false;
    }

    return true;
  };

  const handleImportClick = () => {
    if (validateTokens(tokens)) {
      onImportClick(tokens.split('\n'));
      setError(null);
    }
  };

  useEffect(() => {
    if (show) {
      setError(null); // Clear the error when the modal is opened.
      setTokens('');
    }
  }, [show]);

  return (
      <Modal isOpen={show} toggle={onCloseClick} centered={true} style={{ maxWidth: '90vw', width: '900px', margin: 'auto auto' }}>
        <ModalBody className="py-3 px-5">
          <div className="mt-2 text-center">
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Import Tokens</h4>
              <p className="text-muted mx-4 mb-0">
                Enter tokens - one per line
              </p>
              <textarea
                  spellCheck="false"
                  className="d-flex gap-2 justify-content-center mt-4 mb-2"
                  style={{ width: '100%', maxHeight: '40vh' }}
                  ref={textareaRef} // Attach the ref to the textarea element
                  value={tokens}
                  onInput={handleInputChange} // Handle input event
                  placeholder={`Enter tokens (up to ${maxCharactersPerLine} characters per line)`}
              />
            </div>
          </div>

          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
                type="button"
                className="btn w-sm btn-light"
                data-bs-dismiss="modal"
                onClick={onCloseClick}
            >
              Close
            </button>
            <button
                type="button"
                className="btn w-sm btn-success"
                onClick={handleImportClick}
            >
              Import
            </button>
          </div>
          {exceededLineLimit && (
              <div className="text-center">
                You have reached the maximum of 100 lines.
              </div>
          )}
          {error && <div className="text-danger text-center">{error}</div>}
        </ModalBody>
      </Modal>
  );
};

ImportTokensModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onImportClick: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  maxCharactersPerLine: PropTypes.number.isRequired,
};

export default ImportTokensModal;