import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
// import DashboardCrm from "../pages/DashboardCrm";

import CrmContacts from "../pages/Crm/CrmContacts";
// import CrmDeals from "../pages/Crm/CrmDeals/index";
// import CrmLeads from "../pages/Crm/CrmLeads/index";
import Servers from "../pages/Servers";

//Task
import TaskDetails from "../pages/Tasks/TaskDetails";
import TaskList from "../pages/Tasks/TaskList";

//Crm Pages
// import CrmCompanies from "../pages/Crm/CrmCompanies";

import Settings from '../pages/Pages/Profile/Settings/Settings';
import Team from '../pages/Pages/Team/Team';
// import Faqs from '../pages/Pages/Faqs/Faqs';
// import Pricing from '../pages/Pages/Pricing/Pricing';

//login
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

import EmailVerify from "../pages/Authentication/EmailVerify";


const authProtectedRoutes = [
  // Tasks
  { path: "/apps-tasks-list-view", component: <TaskList /> },
  { path: "/all-tasks", component: <TaskList /> },
  { path: "/apps-tasks-details", component: <TaskDetails /> },

  // Accounts & Farms
  { path: "/all-accounts", component: <CrmContacts /> },
  { path: "/farms/:farmId/accounts", component: <CrmContacts /> },
  { path: "/account-details/:id", component: <Servers /> },

  //Pages
  { path: "/pages-profile-settings", component: <Settings /> },
  { path: "/farms", component: <Team /> },
  // { path: "/pages-faqs", component: <Faqs /> },
  // { path: "/pages-pricing", component: <Pricing /> },
  // { path: "/pages-sitemap", component: <SiteMap /> },
  // { path: "/pages-search-results", component: <SearchResults /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/farms" />,
  },
  { path: "*", component: <Navigate to="/farms" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  // { path: "/login", component: <ArchiveLogin /> },
  // { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/email-verify", component: <EmailVerify /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
