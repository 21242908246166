import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
//import images
import userImg from "../../assets/images/users/user.png";
// import { createSelector } from 'reselect';

const ProfileDropdown = () => {
    const [userName, setUserName] = useState("");
    const [authUser, setAuthUser] = useState({});

    const checkAuthUserChange = () => {
        const storedAuthUser = sessionStorage.getItem("authUser");
        if (storedAuthUser !== null) {
            const parsedAuthUser = JSON.parse(storedAuthUser);
            if (JSON.stringify(parsedAuthUser) !== JSON.stringify(authUser)) {
                setAuthUser(parsedAuthUser);
                setUserName(parsedAuthUser?.client?.username || parsedAuthUser?.email);
            }
        }
    };

    useEffect(() => {
        // Initialize with the data from sessionStorage
        checkAuthUserChange();

        // Set up a periodic check (you can adjust the interval)
        const checkInterval = setInterval(() => {
            checkAuthUserChange();
        }, 10000); // Check every 10 seconds

        return () => {
            // Clear the interval when the component unmounts
            clearInterval(checkInterval);
        };
    }, []);


    // useEffect(() => {
    //     // alert(typeof sessionStorage.getItem("authUser") !== undefined);
    //     if (sessionStorage.getItem("authUser")) {
    //         const obj = JSON.parse(sessionStorage.getItem("authUser"));
    //         setUserName(obj?.client?.username ? obj.client.username : obj?.email);
    //         setAuthUser(obj);
    //         // setUserName(process.env.REACT_APP_DEFAULTAUTH === "fake" ? obj.username === undefined ? user.first_name ? user.first_name : obj.data.first_name : "Admin" || "Admin" :
    //         //     process.env.REACT_APP_DEFAULTAUTH === "firebase" ? obj.providerData[0].email : "Admin"
    //         // );
    //     }
    // }, [userName]);

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={authUser?.client?.profile_picture || userImg}
                            alt="Header Avatar" />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userName}</span>
                            {/*<span className="d-none d-xl-block ms-1 fs-12 user-name-sub-text">Founder</span>*/}
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">Welcome {userName}!</h6>
                    <DropdownItem className='p-0'>
                        <Link to={process.env.PUBLIC_URL + "/pages-profile-settings"} className="dropdown-item">{/*<span
                                className="badge bg-success-subtle text-success mt-1 float-end">New</span>*/}
                            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Settings</span>
                        </Link>
                    </DropdownItem>
                    {/*<DropdownItem className='p-0'>*/}
                    {/*    <Link to={process.env.PUBLIC_URL + "/profile"} className="dropdown-item">*/}
                    {/*        <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>*/}
                    {/*        <span className="align-middle">Profile</span>*/}
                    {/*    </Link>*/}
                    {/*</DropdownItem>*/}

                    {/*<DropdownItem className='p-0'>*/}
                    {/*    <Link to={process.env.PUBLIC_URL + "/apps-chat"} className="dropdown-item">*/}
                    {/*        <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span*/}
                    {/*            className="align-middle">Messages</span>*/}
                    {/*    </Link>*/}
                    {/*</DropdownItem>*/}
                    <DropdownItem className='p-0'>
                        <Link to={"/all-tasks"} className="dropdown-item">
                            <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span
                                className="align-middle">Taskboard</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link to={process.env.PUBLIC_URL + "/pages-faqs"} className="dropdown-item">
                            <i
                                className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span
                                    className="align-middle">Help</span>
                        </Link>
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    {/*<DropdownItem className='p-0'>*/}
                    {/*    <Link to={process.env.PUBLIC_URL + "/pages-profile"} className="dropdown-item">*/}
                    {/*        <i*/}
                    {/*            className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span*/}
                    {/*                className="align-middle">Balance : <b>$0</b></span>*/}
                    {/*    </Link>*/}
                    {/*</DropdownItem >*/}
                    {/*<DropdownItem className='p-0'>*/}
                    {/*    <Link to={process.env.PUBLIC_URL + "/pages-profile-settings"} className="dropdown-item">*/}
                    {/*        <span*/}
                    {/*            className="badge bg-success-subtle text-success mt-1 float-end">New</span><i*/}
                    {/*                className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span*/}
                    {/*                    className="align-middle">Settings</span>*/}
                    {/*    </Link>*/}
                    {/*</DropdownItem>*/}
                    {/*<DropdownItem className='p-0'>*/}
                    {/*    <Link to={process.env.PUBLIC_URL + "/auth-lockscreen-basic"} className="dropdown-item">*/}
                    {/*        <i*/}
                    {/*            className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Lock screen</span>*/}
                    {/*    </Link>*/}
                    {/*</DropdownItem>*/}
                    <DropdownItem className='p-0'>
                        <Link to={process.env.PUBLIC_URL + "/logout"} className="dropdown-item">
                            <i
                                className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                                    className="align-middle" data-key="t-logout">Logout</span>
                        </Link>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;